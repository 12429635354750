<script setup lang="ts">
import type { IBlogPostResponse } from '@/types/types'
import { Cookie } from '@/types/enums'

const locationParam = useCookie(Cookie.LocationParam)

const content = useContent()

const { data } = useFetch<IBlogPostResponse>('/api/blog', {
  query: {
    location: locationParam,
  },
  key: 'BlogFeaturedPosts',
  transform: snakeToCamel,
})
</script>

<template>
  <section v-if="data && data?.items?.length" class="container mx-auto px-5 lg:px-0 py-14 flex flex-col items-center">
    <h3 class="text-center text-neutral-300 text-2xl font-sora font-semibold mb-2">
      Já conhece o Blog da Trevo?
    </h3>
    <p class="text-center text-neutral-200 mb-6">
      Confira abaixo algumas das nossas matérias mais acessadas.
    </p>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
      <div v-for="(post, postIdx) in data?.items.slice(0, 3)" :key="`post${postIdx}`" data-testid="blog-post">
        <Img :src="post.thumbnailLink" :alt="`Thumbnail para ${post.title}`" classes="w-full h-[215px] bg-neutral-100 rounded-2xl object-cover mb-4" />
        <h4 class="h-12 text-neutral-200 text-base mb-2 line-clamp-2">
          {{ post.title }}
        </h4>
        <NuxtLink
          :to="post.link"
          target="_blank"
          external
          class="text-primary-400 text-base font-semibold underline"
        >
          Continuar lendo...
        </NuxtLink>
      </div>
    </div>
    <NuxtLink
      :to="content.blogUrl"
      class="cursor-pointer px-4 py-3 bg-secondary-300 hover:bg-secondary-400 transition-all rounded-md text-white text-sm font-sora font-semibold"
    >
      Veja mais posts no Blog da Trevo
    </NuxtLink>
  </section>
</template>
